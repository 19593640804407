import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {AlertController} from '@ionic/angular';

import {Router} from '@angular/router';
import {GlobalVariables} from '../../../../global-variables';
import {ApiConfiguration} from "../configuration";
import {ApiService} from "../api.service";

@Injectable({
    providedIn: 'root'
})
export class ApiQrEvent {

    constructor(
        private httpClient: HttpClient,
        private router: Router,
        private gv: GlobalVariables,
        private alertController: AlertController,
        private apiConfig: ApiConfiguration,
        private apiService: ApiService
    ) {

    }

    getQrEvent(
        payload: any,
        success: any,
        companyId: number
    ): Promise<any> {
        return new Promise(resolve => {
            this.httpClient.get(
                `${this.gv.__HOST_URL}/api/v1/companies/${companyId}/events?envelope=true&token=${this.gv.__TOKEN}&locale=${this.gv.__GLOBAL_LANG}&limit=100&status=1`,
                payload)
                .subscribe(data => {
                    resolve(data);
                    this.apiService.errorMessages(data, success);
                }, function (data) {
                    console.log('error');
                });
        });
    }

    getUserName(
        payload: any,
        success: any,
        name: string,
        companyId: string | number,
        eventId: string | number,
        queryLimit: number,
        queryOffset: number
    ): Promise<any> {
        return new Promise(resolve => {
            this.httpClient.get(
                `${this.gv.__HOST_URL}/api/v1/companies/${companyId}/events/tickets?event/id=${eventId}&comment=*${name}*&token=${this.gv.__TOKEN}&locale=${this.gv.__GLOBAL_LANG}&limit=${queryLimit}&offset=${queryOffset}&envelope=true&order=comment%20ASC`,
                payload)
                .subscribe(data => {
                    resolve(data);
                    this.apiService.errorMessages(data, success);
                }, function (data) {
                    console.log('error');
                });
        });
    }
}
