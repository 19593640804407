import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';

import {AlertController} from '@ionic/angular';

import {Router} from '@angular/router';
import {GlobalVariables} from '../../../../global-variables';
import {ApiConfiguration} from "../configuration";
import {ApiService} from "../api.service";
import {AuthService} from "../../auth/auth.service";

@Injectable({
    providedIn: 'root'
})
export class ApiQrTicket {

    baseURL: string = '';

    token: string = '';

    data: any;
    headers: any;
    headersJSON: any;

    constructor(private httpClient: HttpClient, private router: Router, public gv: GlobalVariables, public alertController: AlertController, private apiConfig: ApiConfiguration, private apiService: ApiService, public authService: AuthService) {
        this.baseURL = this.gv.__BASE_URL;
        this.token = this.gv.__TOKEN;

        this.headers = {
            headers: new HttpHeaders(this.apiConfig.headers)
        };
        this.headersJSON = this.apiConfig.headersJSON;
    }

    /**
     * ticket check
     * @param payload
     * @param success
     */
    postQrTicket(payload: any, success: any, id: number | string, event: number | string) {
        const self = this;

        return new Promise(resolve => {
            this.httpClient.post(this.gv.__HOST_URL + '/api/v1/companies/' + id + '/events/' + event + '/access?envelope=true' + '&token=' + this.gv.__TOKEN + '&locale=' + this.gv.__GLOBAL_LANG, payload, this.headers)
                .subscribe(data => {
                    this.data = data;
                    resolve(this.data);
                    self.apiService.errorMessages(data, success);
                }, function (data) {
                    console.log('error');
                    this.data = data;
                    resolve(this.data);
                    self.apiService.errorMessages(data, success);
                });
        });
    }

    getEventEnteredNumber(payload: any, success: any, id: number, event: number) {
        const self = this;

        return new Promise(resolve => {
            this.httpClient.get(this.gv.__HOST_URL + '/api/v1/companies/' + id + '/events/' + event + '?envelope=true' + '&token=' + this.gv.__TOKEN + '&locale=' + this.gv.__GLOBAL_LANG, payload)
                .subscribe(data => {
                    this.data = data;
                    resolve(this.data);
                    self.apiService.errorMessages(data, success);
                }, function (data) {
                    console.log('error');
                    this.data = data;
                    resolve(this.data);
                    self.apiService.errorMessages(data, success);
                });
        });
    }

    getTicketIsEntered(payload: any, success: any, ticketId: number, id: string | number, eventId: string | number) {
        return new Promise(resolve => {
            this.httpClient.get(this.gv.__HOST_URL + '/api/v1/companies/' + id + '/events/' + eventId + '/entries?ticket/id=' + ticketId + '&envelope=true' + '&token=' + this.gv.__TOKEN + '&locale=' + this.gv.__GLOBAL_LANG + '&limit=100', payload)
                .subscribe(data => {
                    this.data = data;
                    resolve(this.data);
                    this.apiService.errorMessages(data, success);
                }, function (data) {
                    console.log('error');
                });
        });
    }
}
