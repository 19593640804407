import {Injectable, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';

import {AlertController, IonicModule, IonicRouteStrategy} from '@ionic/angular';

import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';

import {
    HTTP_INTERCEPTORS,
    HttpClient,
    HttpClientModule,
    HttpHandler,
    HttpInterceptor,
    HttpRequest
} from '@angular/common/http';
import {ApiConfiguration} from "./shared/services/api/configuration";
import {GlobalVariables} from "./global-variables";
import {BarcodeScanner} from '@awesome-cordova-plugins/barcode-scanner/ngx';

import {IImageLoaderOptions, NgxProgressiveImageLoaderModule} from 'ngx-progressive-image-loader';
import {FormsModule} from "@angular/forms";
import {SearchModalComponent} from "./search-modal/search-modal.component";
import {StoreModule} from '@ngrx/store';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {AppStoreModule} from "./shared/store/app-store.module";
import { environment } from '../environments/environment';
import {ErrorMessageService} from "./shared/error-message/error-message.service";
import {EventGuard} from "./shared/guards/event.guard";
import {ScanGuard} from "./shared/guards/scan.guard";


@Injectable()
export class NoCacheHeadersInterceptor implements HttpInterceptor {
    intercept(req: HttpRequest<any>, next: HttpHandler) {
        const authReq = req.clone({
            setHeaders: {
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache',
                'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'
            }
        });
        return next.handle(authReq);
    }
}

@NgModule({
    declarations: [AppComponent, SearchModalComponent],
    entryComponents: [],
    imports: [
        BrowserModule,
        IonicModule.forRoot(),
        FormsModule,
        AppRoutingModule,
        StoreDevtoolsModule.instrument(),
        AppStoreModule,
        NgxProgressiveImageLoaderModule.forRoot(<IImageLoaderOptions>{
            rootMargin: '30px',
            threshold: 0.1,
            imageRatio: 16 / 9,
            placeholderImageSrc:
                'data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiBoZWlnaHQ9IjQ0IiB2aWV3Qm94PSIwIDAgNDIgNDQiIHdpZHRoPSI0MiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGZpbGw9IiNlNjY2NjUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+PHBhdGggZD0ibTM0LjQ3NCAzNy42N2E0LjE3MyA0LjE3MyAwIDAgMSAtLjQzMy0uMzQybC0uMDUtLjA0LS4wMDYtLjAxMWE0LjEyIDQuMTIgMCAwIDEgLS41MzctNS40NzFjLjA5My0uMTc0IDEuMDk1LTEuMjQ2IDEuMDk1LTEuMjQ2bDYuOTQ4LTcuNjA1cy0zLjc0Ni0xLjY1Ny01Ljk2OC43NDFjLTEuMTg5IDEuMjg1LTcuNDcgOC4zNzctNy40NyA4LjM3N2wtMi43ODMtMi40MnMxMS4wNjYtMTIuNTk5IDEwLjYzLTEyLjExNWMwIDAgNS40NzUtNi45MjQtMi42NjMtMTIuOTEtOC4wODMtNS45NDgtMTMuNjgzLjU1MS0xMy42ODMuNTUxbC0xMC44NzcgMTIuNjQ0cy0xLjc1MSAyLjExNC4xNTUgMy41MTFjMS43NjMgMS4yOTMgMy42MDgtMS4wNDMgMy42MDgtMS4wNDNsOS4zMTktMTAuNjlzNC4wODMtNC45NjggOC40MzMtMS44M2M1LjMyIDMuODM4IDEuOTYgNy44NjMgMS45NiA3Ljg2M2wtMTAuMzQ0IDEyLjAwOHMtMS4yNzMgMS4yNzUtMS4xOTMgMi41NTZjLjA4IDEuMjg0IDEuNjM1IDIuNTMyIDEuNjM1IDIuNTMybDIuODkgMi4zMDVzLTIuMjAyIDIuNDgxLTUuODQzLS40NTNjLTQuMjI5LTMuNDEtMS4wMDYtOC4xNjMtMS4wMDYtOC4xNjNsMTAuMjc2LTEyLjIzNXMxLjYwNS0xLjkxLS40MDEtMy4yNzdjLTEuODEzLTEuMjM1LTMuMi40ODYtMy4yLjQ4NmwtMTAuMzMgMTIuMDQzcy0zLjQyNyA0LjQ1OC03LjkyNi42OWMtNC4yNC0zLjU1Mi0xLjIxLTcuMzgtMS4yMS03LjM4bDYuNjU5LTguMTI5YzQuNzYxLTQuOTA5IDEuMTYxLTguNjE3IDEuMTYxLTguNjE3bC0xMC40MzUgMTIuNjk5cy03LjA1NSA3LjQ3Mi45IDE0LjE5M2M1LjcgNC44MTggOS42OTUgMS4zMjggOS42OTUgMS4zMjguMDI4LS4wMzMtMi4xMSA1LjIwMSAyLjk3MSA5LjIwNiA2LjA5NiA0LjgwNiAxMS45OTUuMTgzIDExLjk5NS4xODNsNy41MjUgNi4yNjJjMi4wMDEtMi45LS41MzYtNS40NDQtLjUzNi01LjQ0NGwtLjk2MS0uNzU4eiIvPjxwYXRoIGQ9Im0zOC42NiAzNC40NWEyLjA4IDIuMDggMCAxIDEgLTQuMTYgMCAyLjA4IDIuMDggMCAwIDEgNC4xNiAweiIvPjwvZz48L3N2Zz4='
        }),
        HttpClientModule,
        StoreModule.forRoot({}, {}),
        StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production })
    ],
    providers: [
        ErrorMessageService,
        EventGuard,
        ScanGuard,
        AlertController,
        {
            provide: RouteReuseStrategy,
            useClass: IonicRouteStrategy
        }, GlobalVariables, ApiConfiguration, HttpClient, {
            provide: HTTP_INTERCEPTORS,
            useClass: NoCacheHeadersInterceptor,
            multi: true
        },
        BarcodeScanner
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
}
