import {createFeatureSelector, createSelector} from "@ngrx/store";
import {EventIdInterface} from "../../interfaces/event.interface";
import {EventsApiDataContent, EventsApiResponseContent} from "../../../shared/interfaces/events";

const eventFeatureSelector = createFeatureSelector('eventId');
export const eventSelector = createSelector(
    eventFeatureSelector, (props: EventsApiDataContent) => {
        return {
            data: props.data
        }
    }
)
