import {HttpHeaders} from '@angular/common/http';

export class ApiConfiguration {
  headers = {
    'Content-Type': 'application/x-www-form-urlencoded'
  };

  headersFormData = {
    'Authorization': 'Basic ' + btoa('wponline:wpoffice')
  };

  headersJSON = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'Basic ' + btoa('wponline:wpoffice')
  });
}
