import {Injectable} from '@angular/core';
import {Router} from "@angular/router";

@Injectable({providedIn: 'root'})
export class ResetService {

  constructor(
    private router: Router
  ) {

  }

  reset(): any {
    // for (var key in localStorage) {
    //   if (key.indexOf('wp-qr') > -1) {
    //     localStorage.removeItem(key);
    //   }
    // }
    this.router.navigateByUrl('/events/list');
  }
}
