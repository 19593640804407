import {Injectable} from "@angular/core";
import {AlertController} from "@ionic/angular";
import {ErrorMessages} from "../interfaces/error-messages";

@Injectable()
export class ErrorMessageService {
    constructor(
        private alertCtrl: AlertController
    ) {
    }

    async showSimpleErrorMessage(
        {header = '', subHeader = '', message = ''}: ErrorMessages
    ) {
        const alert = await this.alertCtrl.create({
            header: header,
            subHeader: subHeader,
            message: message,
            buttons: ['OK'],
        });

        await alert.present();
    }
}
