import {Component, OnInit} from '@angular/core';
import {AlertController, ModalController, NavParams} from "@ionic/angular";
import {ApiQrEvent} from "../shared/services/api/qr/event";
import {LocalStorageService} from "../shared/services/local-storage.service";
import {ApiQrTicket} from "../shared/services/api/qr/ticket";
import {EventsApiResponseContent} from "../shared/interfaces/events";

@Component({
    selector: 'app-search-modal',
    templateUrl: './search-modal.component.html',
    styleUrls: ['./search-modal.component.scss'],
})
export class SearchModalComponent implements OnInit {

    private companyID: string | number;
    private eventID: string | number;

    private queryOffset = 0;
    private queryLimit = 100;

    public queryPercentage = 0;
    public ticketName: string = '';
    public userArray = [];
    public searchIsProgress = false;

    constructor(
        private modalCtrl: ModalController,
        private apiQrEvent: ApiQrEvent,
        private apiQrTicket: ApiQrTicket,
        private lss: LocalStorageService,
        private alertController: AlertController,
        private navParams: NavParams
    ) {
    }

    ngOnInit() {
        const modalPassed: EventsApiResponseContent = this.navParams.get('data');
        const companyId: number = this.navParams.get('companyId');

        this.companyID = companyId;
        this.eventID = modalPassed.id;
    }

    close() {
        return this.modalCtrl.dismiss(null, 'cancel');
    }

    search() {
        if (this.queryOffset === 0)
            this.userArray = [];

        this.searchIsProgress = true;
        this.apiQrEvent.getUserName('', () => {

            },
            this.ticketName,
            this.companyID,
            this.eventID,
            this.queryLimit,
            this.queryOffset
        )
            .then((data: any) => {
                if (data.meta.returnedRecords === this.queryLimit) {
                    this.queryOffset += this.queryLimit;
                    this.userArray.push(...data.content);
                    this.queryPercentage = this.queryOffset / data.meta.queryRecords * 100;
                    this.search();
                } else {
                    this.userArray.push(...data.content);
                    this.queryOffset = 0;
                    this.searchIsProgress = false;
                }
            });
    }

    clearField(): void {
        this.ticketName = '';
    }

    async enterTheUser(item: any) {
        const ticketName = item.comment;
        const alert = await this.alertController.create({
            header: ticketName,
            subHeader: 'What do you want?',
            message: `Barcode: ${item.voucher.barcode.code}`,
            buttons: [
                {
                    text: 'Cancel',
                    role: 'cancel',
                    handler: () => {
                        console.log('Alert canceled');
                    },
                },
                {
                    text: 'Validation of the ticket',
                    cssClass: 'enter',
                    role: 'confirm',
                    handler: () => {
                        this.checkTicket(item.voucher.barcode.code);
                        this.search();
                    },
                }
            ],
        });
        await alert.present();
    }

    checkTicket(barcode: string): void {
        this.apiQrTicket.postQrTicket('barcode=' + barcode, function () {

        }, this.companyID, this.eventID)
            .then(data => {
                if ((<any>data).error !== undefined) {
                    if (typeof (<any>data).error.content.error !== 'undefined') {

                        return;
                    }
                } else {
                    if ((<any>data).content.isValid) {

                    } else {

                    }
                }
            });
    }
}
