import {Injectable} from "@angular/core";
import {CanActivate, Router} from "@angular/router";
import {Store} from "@ngrx/store";
import {eventSelector} from "../../events/store/selectors/event.selector";

@Injectable()
export class ScanGuard implements CanActivate {
    constructor(
        private router: Router,
        private store: Store
    ) {
    }

    canActivate(): boolean {
        let result = false;

        this.store.select(eventSelector).subscribe((e: any) => {
            if (e.data === undefined) {
                this.falseRoute();
                result = false;
                return;
            }

            if ((<any>e).data.id !== undefined) {
                result = true;
            } else {
                this.falseRoute();
                result = false;
            }
        }).unsubscribe();

        return result;
    }

    falseRoute() {
        this.router.navigateByUrl('/auth');
    }
}
