import {Injectable} from "@angular/core";
import {CanActivate, Router} from "@angular/router";
import {Store} from "@ngrx/store";
import {homeSelector} from "../../home/store/selectors/home.selector";

@Injectable()
export class EventGuard implements CanActivate {
    constructor(
        private router: Router,
        private store: Store
    ) {
    }

    canActivate(): boolean {
        let result = false;

        this.store.select(homeSelector).subscribe((e) => {
            if (e.id !== undefined) {
                result = true;
            } else {
                this.router.navigateByUrl('/auth');
                result = false;
            }
        });

        return result;
    }
}
