import {createReducer, on} from "@ngrx/store";
import {homeInitialState} from "./home.state";
import {homeSaveIdAction} from "../actions/home.action";
import {HomeIdResponseInterface} from "../../interfaces/home.interface";

export const homeIdReducer = createReducer(
    homeInitialState,
    on(homeSaveIdAction, (_state: HomeIdResponseInterface, {id}) => {
        return {
            ..._state, requestState: 'RESOLVED', id: id
        }
    })
);
