import {createReducer, on} from "@ngrx/store";
import {eventInitialState} from "./event.state";
import {eventSaveObjectAction} from "../actions/event.action";
import {EventIdResponseInterface} from "../../interfaces/event.interface";
import {EventsApiResponseContent} from "../../../shared/interfaces/events";

export const eventIdReducer = createReducer(
    eventInitialState,
    on(eventSaveObjectAction, (_state: EventIdResponseInterface, {data}) =>{
        return {
            ..._state, data: data
        }
    })
);
