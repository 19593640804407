import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {StoreModule} from "@ngrx/store";
import {homeIdReducer} from "../../home/store/reducers/home.reducer";
import {eventIdReducer} from "../../events/store/reducers/event.reducer";
import {deviceReducer} from "../../scan-modal/store/reducers/device.reducer";

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature('companyId', homeIdReducer),
        StoreModule.forFeature('eventId', eventIdReducer),
        StoreModule.forFeature('deviceConfig', deviceReducer)
    ]
})
export class AppStoreModule {
}
