import {createFeatureSelector, createSelector} from "@ngrx/store";
import {HomeIdInterface} from "../../interfaces/home.interface";

const homeFeatureSelector = createFeatureSelector('companyId');
export const homeSelector = createSelector(
    homeFeatureSelector, (props: HomeIdInterface) => {
        return {
            id: props.id
        }
    }
)
