import {Injectable} from '@angular/core';
import {Router} from "@angular/router";

@Injectable({providedIn: 'root'})
export class LocalStorageService {
  private localStorageKey = 'wp-qr';

  constructor(
    private router: Router
  ) {

  }

  save(subname: string, data: any): void {
    localStorage.setItem(this.localStorageKey + '/' + subname, data.toString());
  }

  load(subname: string): any {
    const data = localStorage.getItem(this.localStorageKey + '/' + subname);
    if (!data) return null;
    return data as any;
  }

  reset(): any {
    for (var key in localStorage) {
      if (key.indexOf('wp-qr') > -1) {
        localStorage.removeItem(key);
      }
    }
    this.router.navigateByUrl('/home');
  }
}
