export class GlobalVariables {

  /**
   * base url
   */
  __BASE_URL = '';

  /**
   * host url
   */
  __HOST_URL = '';

  /**
   * user auth
   */
  __TOKEN = 'NzIyNGY3YzAyNmVjNzllNDgyNTU3YWFkc7rNupzRnd9zYpoASIv3CqixrOR6Qp6DtiYqDjKfmPs6-VEESMmfOSp_T98FIL5Vzao';

  /**
   * user profile ID
   */
  __USER_PROFILE_ID: any;

  /**
   * SAFE URL-s
   */
  __SAFE_URLS = [
    'login-gate',
    'registration-step2'
  ]

  /**
   * language
   */
  __GLOBAL_LANG = 'en-US';

  /**
   * platform
   */
  __GLOBAL_PLATFORM = '';

  /**
   * operacios rendszere
   */
  __GLOBAL_OS = '';
  __VER = '';
  __ID = '__dec_'

  /**
   * event kezeléshez
   */
  __COMPANY_ID = null;
  __EVENT_ID = null;
}
