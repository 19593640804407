import {createReducer, on} from "@ngrx/store";
import {deviceInitialState} from "./device.state";
import {deviceAction} from "../actions/device.action";
import {DeviceResponseInterface} from "../../interfaces/device.interface";

export const deviceReducer = createReducer(
    deviceInitialState,
    on(deviceAction, (_state: DeviceResponseInterface, {cameraId, cameraLabel}) => {
        return {
            ..._state,
            requestState: 'RESOLVED',
            cameraId: cameraId,
            cameraLabel: cameraLabel
        }
    })
);
