import {Component, Injectable} from '@angular/core';
import {AlertController} from '@ionic/angular';
import {Router} from "@angular/router";
import {Location} from "@angular/common";
import {AuthService} from "../auth/auth.service";
import {GlobalVariables} from "../../../global-variables";
import {LogService} from "../log/log.service";
import {ResetService} from "../reset.service";

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(
    public alertController: AlertController,
    public router: Router,
    public location: Location,
    public authService: AuthService,
    public gv: GlobalVariables,
    public log: LogService,
    private resetService: ResetService
  ) {
  }

  async errorMessages(errorData, success) {
    let
      key,
      errorArray = [];
    const self = this;
    let secondaryErrorMessage = '';
    let secondaryButtonIsActive = true;

    /**
     * LOG
     */
    this.log.serverError(errorData);

    /**
     * full invalid or bad request (we have status code error)
     * -------------------------------------------------------
     */
    if (errorData.status !== undefined) {
      /** error code */
      switch (errorData.status) {
        case 401:
          this.resetService.reset();
          return
          break;
        case 403:
          // this.resetService.reset();
          return
          break;
      }
      /** error in content */
      // const alert = await this.alertController.create({
      //   cssClass: 'api-alert request-error',
      //   header: '',
      //   subHeader: '',
      //   message: errorData.error.content.error.message + '<br>' + secondaryErrorMessage,
      //   buttons: [
      //     // {
      //     //     text: 'Go to last page',
      //     //     role: 'goback',
      //     //     handler: () => {
      //     //         if (secondaryButtonIsActive)
      //     //             this.location.back();
      //     //     }
      //     // },
      //     {
      //       text: 'OK',
      //       role: 'ok',
      //       handler: () => {
      //
      //       }
      //     }]
      // });
      //
      // await alert.present();
    }

    /**
     * valid request but we got error (status code OK, but not valid)
     * -------------------------------------------------------
     */

    if (errorData.meta.statusCode !== 200) {
      this.resetService.reset();
    }
    if (errorData.status == undefined && errorData.content.isValid === false) {
      let str = '';
      for (const [key, value] of Object.entries(errorData.content.messages)) {
        let iKey = key;
        if (typeof value === 'object') {
          for (const [key, value] of Object.entries(errorData.content.messages[iKey])) {
            str = str + '<strong>' + iKey + '</strong> ' + `${value}` + '<br>';
          }
        } else {
          str = str + '<strong>' + iKey + '</strong> ' + `${value}` + '\n';
        }

      }

      /** error in content */
      const alert = await this.alertController.create({
        cssClass: 'api-alert request-error',
        header: '',
        subHeader: '',
        message: str,
        buttons: [
          // {
          //     text: 'Go to last page',
          //     role: 'goback',
          //     handler: () => {
          //         this.location.back();
          //     }
          // },
          {
            text: 'OK',
            role: 'ok',
            handler: () => {

            }
          }]
      });

      await alert.present();
    }
  }
}
