import {Injectable, ErrorHandler, NgZone} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class LogService {

  error = [];
  logOfConsole = [];
  sendIsActive = false;

  constructor(
    public ngZone: NgZone
  ) {

  }

  connect() {

  }

  serverError(error: any) {

  }

  sendLOG() {

  }

  async send() {

  }
}
