import {Injectable} from '@angular/core';
import {GlobalVariables} from "../../../global-variables";
import {promise} from "selenium-webdriver";
import {Platform} from "@ionic/angular";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private platform: Platform, public gv: GlobalVariables) {
  }

  getToken() {

  }

  /**
   * UNUSED
   * ----------------------------------------------
   */
  getStore() {
    const self = this;

  }

  setStore(data: string) {
    window.localStorage.setItem(this.gv.__ID + 'token', data);
    this.gv.__TOKEN = window.localStorage.getItem(this.gv.__ID + 'token');
  }

  createSecureStorage(data) {

  }

}
