import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {AUTH, EVENTS_LIST, EVENTS_SCAN, FAST_SCAN} from "./shared/constants/route.const";
import {EventGuard} from "./shared/guards/event.guard";
import {ScanGuard} from "./shared/guards/scan.guard";

const routes: Routes = [
    {
        path: AUTH,
        loadChildren: () => import('./home/home.module').then(m => m.HomePageModule)
    },
    {
        path: EVENTS_LIST,
        canActivate: [EventGuard],
        loadChildren: () => import('./events/events.module').then(m => m.EventsPageModule)
    },
    {
        path: EVENTS_SCAN,
        canActivate: [ScanGuard],
        loadChildren: () => import('./scan/scan.module').then(m => m.ScanPageModule)
    },
    {
        path: FAST_SCAN,
        canActivate: [ScanGuard],
        loadChildren: () => import('./scan-modal/scan-modal.module').then(m => m.ScanModalModule)
    },
    {
        path: '',
        redirectTo: 'auth',
        pathMatch: 'full'
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
